<template>
  <div class="mycenter">
    <main class="graybg">
      <div class="ce-wrap flex">
        <!-- 左侧菜单 -->
        <div class="men_left flex">
          <el-menu
            class="el-menu-vertical-demo"
            router
            @open="handleOpen"
            @close="handleClose"
            :default-openeds="openeds"
            :default-active= 'active'
          >
            <el-submenu index="1">
              <template slot="title">
                <img class="menus" src="../../assets/image/shezhi.png" alt srcset>
                <span>账号管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/basic">基本资料</el-menu-item>
                <el-menu-item index="/security">账户安全</el-menu-item>
                <el-menu-item index="/address">地址管理</el-menu-item>
                <!-- <el-menu-item index="/invoice">发票中心</el-menu-item> -->
                <!-- <el-menu-item index="/logonbind">登录绑定</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <img class="menus" src="../../assets/image/jiaoyiguanli.png" alt srcset>
                <span>交易管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/myNeeds">我的需求</el-menu-item>
                <!-- <el-menu-item index="/myPrice">我的比价</el-menu-item> -->
                <el-menu-item index="/purchaseOrder">我的订单</el-menu-item>
                <!-- <el-menu-item index="/tenderOrder">招标订单</el-menu-item> -->
                <!-- <el-menu-item index="/myComment">我的评价</el-menu-item> -->
                <!-- <el-menu-item index="/myAfter">退款售后</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <img class="menus" src="../../assets/image/shoucang.png" alt srcset>
                <span>我的收藏</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/proCollect">产品收藏</el-menu-item>
                <el-menu-item index="/shopCollect">店铺收藏</el-menu-item>
                <!-- <el-menu-item index="/myCollect">我的素材</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <img class="menus" src="../../assets/image/jifenduihuan.png" alt srcset>
                <span>积分兑换</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/integral">我的积分</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <img class="menus" src="../../assets/image/wodetuiguang.png" alt srcset>
                <span>我的推广</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/generalize">推广中心</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="6">
              <template slot="title">
                <img class="menus" src="../../assets/icon/xiaoxizhongxin.png" alt srcset>
                <span>消息中心</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/notice">公告通知</el-menu-item>
                <el-menu-item index="transaction">交易提醒</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
          </el-menu>
        </div>
        <!-- 右侧内容 -->
        <div class="men-right flex-1">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'mycenter',
  data() {
    return {
      openeds: ['1', '2', '3', '4', '5', '6'],
      active: '/basic'
    };
  },
  mounted() {
    const {path} = this.$route
     this.active = path
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  watch: {
    '$route.path' (val){
      this.active= val
    }
  },
};
</script>

<style lang='scss' scoped>
@import '../../assets/scss/common.scss';
@import '../../assets/scss/reset.scss';

.mycenter {
  .graybg {
    background: #f5f5f5;
    overflow: hidden;
    // padding-top: 20px;
    .ce-wrap {
      max-width: 1200px;
      width:100%;
      margin: 20px auto;
      overflow: hidden;
      .men_left {
        width: 180px;
        min-width: 180px;
        margin-right: 20px;
        height: 100%;
        .el-menu {
          border: none;
        }
        .el-menu-vertical-demo{
          width:180px;
        }
        .el-submenu__icon-arrow {
          display: none;
        }
        .menus {
          margin-right: 8px;
          padding-right: 8px;
        }
       /deep/ .el-submenu .el-menu-item {
          min-width: 168px;
          padding-left: 58px !important;
        }
        .el-menu-item {
          height: 40px;
          line-height: 40px;
        }
        /deep/ .el-submenu__title {
          height: 40px;
          line-height: 40px;  
        }
     
        .el-submenu__title:hover {
          background: #ffffff;
        }
        .el-menu-item-group__title {
          padding: 0;
        }
        .el-submenu__title {
          font-size: 15px;
          color: #333333;
        }
        .el-menu-item:hover {
          background: #ffffff;
        }
        .el-menu-item.is-active {
          color: #d82229;
          background: #ffffff;
        }
      }
    }
    .men-right {
      max-width: 1000px;
      min-height: 500px;
      background: #ffffff;
    }
  }
}

/deep/ .el-menu-item-group__title{
  display: none;
}
/deep/ .el-submenu__title:hover{
  background: #f5f5f5 ;
}
</style>
